<template>
  <div>
    <v-container>
      <div>
        <v-text-field
          label="Titel"
          v-model="blog.titel"
          outlined
        ></v-text-field>
        <v-text-field
          label="Onderwerp"
          v-model="blog.onderwerp"
          outlined
        ></v-text-field>

        <v-select
          v-model="blog.categorie"
          :items="categories"
          id="name"
          outlined
          label="Categorie"
          item-value="naam"
          item-text="naam"
        ></v-select>
      </div>
      <v-divider> </v-divider>
      <v-row>
                  <v-col
                    v-for="item in images"
                    :key="item.name"
                    class="d-flex child-flex"
                    cols="4"
                  >
                    <v-img
                      :src="`https://server.k-env.nl/folders/${item.loc}`"
                      alt="Item Afbeelding"
                      height="150"
                      width="350"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                      <template v-slot:error>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                          style="color: red;"
                        >
                          <v-icon>mdi-alert</v-icon> Kan afbeelding niet laden
                        </v-row>
                      </template>
                      <!-- <v-icon
                        color="red"
                        @click="deleteImage(item)"
                        class="delete-icon"
                        >mdi-close</v-icon
                      > -->
                    </v-img>
                  </v-col>
                </v-row>
      <v-file-input
        v-model="files"
        label="Een afbeelding toevoegen"
        multiple
        :show-size="true"
        @change="handleFileChange"
      ></v-file-input>
      <vue-editor
        :editorToolbar="customToolbar"
        id="editor1"
        v-model="blog.content"
      >
      </vue-editor>

      <div class="text-center">
        <v-btn
          style="color:white;background-color:#cb403d;margin-right:10px"
          @click="goBack()"
        >
          Ga terug
        </v-btn>
        <v-btn
          @click="updateBlog()"
          style="background-color: #147D81; color: white;"
        >
          Blog wijzigigen
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import apiFolder from "../api/apiFolders";
import { Quill } from "vue-quill-editor";
import { VueEditor } from "vue2-editor";
import apiBlog from "../api/apiBlog";
function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}

const fontList = [
  "Arial",
  "Courier",
  "Garamond",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];
const fontNames = fontList.map((font) => getFontName(font));
const fonts = Quill.import("formats/font");
fonts.whitelist = fontNames;
Quill.register(fonts, true);

// Add fonts to CSS style
let fontStyles = "";
fontList.forEach(function(font) {
  let fontName = getFontName(font);
  fontStyles +=
    ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" +
    fontName +
    "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" +
    fontName +
    "]::before {" +
    "content: '" +
    font +
    "';" +
    "font-family: '" +
    font +
    "', sans-serif;" +
    "}" +
    ".ql-font-" +
    fontName +
    "{" +
    " font-family: '" +
    font +
    "', sans-serif;" +
    "}";
});

const node = document.createElement("style");
node.innerHTML = fontStyles;
document.body.appendChild(node);

var Size = Quill.import("attributors/style/size");

Size.whitelist = [
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];
Quill.register(Size, true);
import apiBlogCategorie from "../api/apiBlogCategorie";
export default {
  components: {
    VueEditor,
  },

  data() {
    return {
      editing: false,
      files: [],
      images:[],
      htmlForEditor: "",
      blog: {
        titel: "",
        content: "",
        onderwerp: "",
        categorie: "",
      },
      categories: [],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["bold", "italic", "underline"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ font: fonts.whitelist }],
        [
          {
            size: [
              "9px",
              "10px",
              "11px",
              "12px",
              "14px",
              "16px",
              "18px",
              "20px",
              "22px",
              "24px",
              "26px",
              "28px",
            ],
          },
        ],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["link", "image"],
        ["clean"],
      ],
    };
  },

  async mounted() {
    this.fetchImages();
    apiBlogCategorie.haalCategorieOp().then((data) => {
      this.categories = data;
    });
    this.fetchBlog();
  },

  methods: {
    fetchImages() {
      const id = this.$route.params.id;
      const folderName = `blog/${id}`;
      apiFolder.fetchImages(folderName).then((data) => {
        this.images = data;
      });
    },

    goBack() {
      this.$router.push("/Blogs");
    },

    async fetchBlog() {
      try {
        const data = await apiBlog.haalEeBlogId(this.$route.params.id);
        this.blog = data;
      } catch (error) {
        console.error("Kon Blog niet laden:", error);
      }
    },
    async updateBlog() {
      try {
        if (this.files.length > 0) {
          const id = this.$route.params.id;
          console.log("Blog ID:", id);
          for (let i = 0; i < this.files.length; i++) {
            const formData = new FormData();
            formData.append("file", this.files[i]);
            console.log("Uploading file:", this.files[i]);

            try {
              const response = await apiBlog.uploadBlogImage(id, formData);
              console.log("Bestand succesvol geüpload", response);
            } catch (error) {
              console.error(
                "Er is een fout opgetreden bij het uploaden",
                error
              );
              this.$swal.fire("Upload fout", "", "error");
              return;
            }
          }
          this.files = [];
          this.$swal.fire(
            "Alle bestanden zijn succesvol geüpload",
            "",
            "success"
          );
        }
        const id = this.$route.params.id;
        const response = await apiBlog.updateBlog(id, this.blog);
        console.log("Blog update response:", response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.fetchBlog();
      } catch (error) {
        console.error("Update blog error:", error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    // Bestand veranderen handler
    handleFileChange(files) {
      this.files = files;
    },
  },
};
</script>

<style scoped>
.text-center {
  padding-top: 1%;
  text-align: center;
}

#editor,
#editor1 {
  height: 350px;
  width: 100%;
}

.projectButton {
  margin: 10px;
}
</style>

<template>
    <Blogbekijken/>
  </template>
  
  <script>
  import Blogbekijken from "@/components/blogs/Blogbekijken";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        Blogbekijken,
    },
    metaInfo: {
      title: "Blogs bekijken | Dashboard",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>
  
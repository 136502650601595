import { API } from "./api.js";
export default {
  async createAsset(data) {
    try {
      const response = await API.post("/api/rest/v2/folder", data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async getDirectories() {
    try {
      const response = await API.get("/api/rest/v2/folder");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getDirectoriesFiles(folderName) {
    try {
      const response = await API.get(
        `/api/rest/v2/folder?location=${folderName}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async downloadDocument(subfolder, filename) {
    try {
      const response = await API.get(
        `/api/rest/v2/folder/${subfolder}/${filename}`,
        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async deleteFile(subfolder) {
    try {
      const response = await API.delete(`/api/rest/v2/folder/${subfolder}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  async fetchImages(folderName) {
    try {
      const response = await API.get(
        `/api/rest/v2/folder/?location=${folderName}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
};
